import urlcat from 'urlcat';
import { fetchWrapped } from './';

import { userApiV3, userPreferencesApi } from './endpoints';
import { parseValidJSON } from '../utils/parseValidJSON';

const getUserData = async (options) => await fetchWrapped(urlcat(userApiV3, '/profile'), options);
const setUserData = async (options) => {
  //todo: remove this workaround once updateProfile endpoint accept partial user data in payload
  const optionsPayload = { ...options };
  const body = parseValidJSON(optionsPayload.body);
  delete body['userPreferencesCategories'];
  optionsPayload.body = JSON.stringify(body);
  return await fetchWrapped(urlcat(userApiV3, '/updateProfile'), { ...optionsPayload, method: 'POST' });
};
const addViewedProduct = async (options) =>
  await fetchWrapped(urlcat(userApiV3, '/addRecentlyViewedProduct'), { ...options, method: 'POST' });
const removeViewedProducts = async (options) =>
  await fetchWrapped(urlcat(userApiV3, '/removeRecentlyViewedProducts'), { ...options, method: 'DELETE' });
const signup = async (options) => await fetchWrapped(urlcat(userApiV3, '/signUp'), options);
const deleteWishlistProduct = async (options) =>
  await fetchWrapped(urlcat(userApiV3, '/removeFromWishlist'), { ...options, method: 'DELETE' });
const addWishlistProduct = async (options) => await fetchWrapped(urlcat(userApiV3, '/addToWishlist'), options);
const businesSignup = async (options) => await fetchWrapped(urlcat(userApiV3, '/businessSignUp'), options);
const deleteBlogBookmark = async (options) =>
  await fetchWrapped(urlcat(userApiV3, '/removeBlogBookmark'), { ...options, method: 'DELETE' });
const addBlogBookmark = async (options) => await fetchWrapped(urlcat(userApiV3, '/addBlogBookmark'), options);
const deleteAccount = async (options) =>
  await fetchWrapped(urlcat(userApiV3, '/deleteAccount'), { ...options, method: 'POST' });
const cancelAccountDeletion = async (options) =>
  await fetchWrapped(urlcat(userApiV3, '/undoAccountDeletion'), { ...options, method: 'POST' });
const addCategory = async (options) =>
  await fetchWrapped(urlcat(userPreferencesApi, '/addCategory'), { ...options, method: 'POST' });
const removeCategory = async (options) =>
  await fetchWrapped(urlcat(userPreferencesApi, '/removeCategory'), { ...options, method: 'DELETE' });
const getUserPreferences = async (options) => await fetchWrapped(urlcat(userApiV3, '/userPreferences'), options);
const getBackInStockSubscriptions = async (options) =>
  await fetchWrapped(urlcat(userApiV3, '/backInStockSubscriptions'), options);

export default {
  getUserData,
  setUserData,
  addViewedProduct,
  removeViewedProducts,
  signup,
  deleteWishlistProduct,
  addWishlistProduct,
  businesSignup,
  deleteBlogBookmark,
  addBlogBookmark,
  deleteAccount,
  cancelAccountDeletion,
  addCategory,
  removeCategory,
  getUserPreferences,
  getBackInStockSubscriptions,
};

import urlcat from 'urlcat';
import { fetchWrapped } from '.';

import { taxiApiV3 } from './endpoints';

const getPricesChartData = async (productId) => await fetchWrapped(urlcat(taxiApiV3, `/prices/${productId}`));
const getRelatedPricesData = async (isExpired, EAN, productId) => {
  const EANParam = EAN ? `ean=${EAN}` : '';
  const productIdParam = `offer=${productId}`;
  const expiredParam = isExpired ? 'expired=1' : '';

  const search = [expiredParam, productIdParam, EANParam].filter(Boolean).join('&');

  return await fetchWrapped(urlcat(taxiApiV3, `/related/?${search}`));
};
export default {
  getPricesChartData,
  getRelatedPricesData,
};

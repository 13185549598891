import urlcat from 'urlcat';
import { fetchWrapped, PostOptions, applyModifiers, addXCartSession, addUserJWT } from '.';

import { stripeApi } from './endpoints';
const startSession = async (userJWT, cartSession) =>
  await fetchWrapped(
    urlcat(stripeApi, '/create-session/'),
    new PostOptions({ headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]) })
  );

export default {
  startSession,
};
